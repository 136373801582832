import React from "react";

import PrivateMiducRoute from "~/src/components/Authentication/MiducPrivateRoute";
import TokenValidator from "~/src/components/Authentication/MiducTokenValidator";
import Layout from "~/src/components/Layout";
import PromoForm from "~/src/components/promos/PromoForm";
import SEO from "~/src/components/SEO";

const NewPromo = (): JSX.Element => {
  return (
    <Layout>
      {/* @ts-expect-error TokenValidator cannot be used as a JSX element */}
      <TokenValidator>
        {/* @ts-expect-error PrivateRoute cannot be used as a JSX element */}
        <PrivateMiducRoute>
          <SEO title="Examedi Marketing Crear Cupón" />

          <div className="min-h-screen w-full bg-gray-100 relative overflow-y-auto p-4">
            <div className="p-10 max-w-2xl mx-auto bg-white border border-gray-300 rounded-md">
              <div className="mb-8">
                <h1 className="mr-8 text-4xl">Agregar Promoción</h1>
              </div>
              <PromoForm />
            </div>
          </div>
        </PrivateMiducRoute>
      </TokenValidator>
    </Layout>
  );
};

export default NewPromo;
