import React, { useState } from "react";

import { TextField, Button, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import { navigate } from "gatsby";
import Swal from "sweetalert2";

import { CountryLower } from "../../constants/countries";

import LoadingIcon from "~/src/icons/loading";
import { createPromo } from "~/src/utils/api-promos";
import getFileExtension from "~/src/utils/getFileExtension";
import { capitalizeFirstLetter } from "~/src/utils/text";

const PromoForm = (): JSX.Element => {
  const [name, setName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [country, setCountry] = useState<CountryLower>(CountryLower.CHILE);
  const [fileUrl, setFileUrl] = useState<string>("");
  const [redirectionUrl, setRedirectionUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const attribute = e.target.name;
    switch (attribute) {
      case "promo_name":
        setName(newValue);
        break;
      case "promo_image": {
        if (e.target.files) {
          const targetFile = e.target.files[0];
          if (!["jpg", "jpeg", "png"].includes(getFileExtension(targetFile))) {
            Swal.fire({
              icon: "error",
              title: "Debes subir una imagen (jpg, jpeg, png)",
            });
            return;
          }
          if (targetFile.size > 15_000_000) {
            Swal.fire({
              icon: "error",
              title: "Imagen muy pesada",
              text: "La imagen no puede pesar más de 15MB :(",
            });
            return;
          }
          setFileUrl(URL.createObjectURL(targetFile));
          setFile(targetFile);
          Swal.fire({
            icon: "info",
            title: "Imagen agregada",
            text: "Recuerda que es recomendable que la imagen tenga dimensiones aproximadas de 1080 x 1920.",
          });
        }
        break;
      }
      case "country":
        setCountry(newValue as CountryLower);
        break;
      case "redirection_url":
        setRedirectionUrl(newValue);
        if (newValue) {
          Swal.fire({
            icon: "info",
            title: "Enlace agregado",
            text: "Recuerda probar el enlace antes de crear la promoción",
          });
        }
        break;
    }
  };

  const submitPromo = async () => {
    if (!file || !name) return;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);
    formData.append("country", country);
    formData.append("redirection_url", redirectionUrl || "");
    setLoading(true);
    const res = await createPromo(formData);
    setLoading(false);
    if (res) {
      navigate("/promos");
    } else {
      Swal.fire({
        icon: "error",
        title: "Ocurrió un error creando la promo, píde ayuda a alguien de soporte técnico",
      });
    }
  };

  return (
    <>
      <div className="col-span-full md:col-span-1">
        <TextField
          name="promo_name"
          onChange={handleFormChange}
          value={name}
          helperText="Nombre de la promoción"
          fullWidth
        />
      </div>
      <div className="col-span-full md:col-span-1">
        <TextField
          name="country"
          select
          helperText="País donde se mostrará"
          value={country}
          onChange={handleFormChange}
          fullWidth
        >
          {Object.entries(CountryLower).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {capitalizeFirstLetter(value)}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="col-span-full md:col-span-1">
        <TextField
          name="redirection_url"
          onChange={handleFormChange}
          value={redirectionUrl}
          helperText="Enlace de redirección (opcional)"
          fullWidth
        />
      </div>
      {!!redirectionUrl && (
        <a
          className={clsx("w-3/4", "text-blue-500", "hover:underline cursor-pointer")}
          target="_blank"
          rel="noreferrer"
          href={redirectionUrl}
        >
          Prueba el enlace clickeando aquí...
        </a>
      )}
      <div className="col-span-full md:col-span-1">
        <input
          id="promo_image"
          name="promo_image"
          accept="image/*"
          style={{ display: "none" }}
          type="file"
          onChange={handleFormChange}
        />
        <p className="mt-4 mb-0 p-0 text-gray-600">Imagen para la promoción</p>
        <div className={clsx("flex flex-row gap-x-2")}>
          <label htmlFor="promo_image">
            <Button component="span" disabled={loading || !!file} variant="contained" color="primary">
              Subir Imagen
            </Button>
          </label>
          <Button
            component="span"
            onClick={() => setFile(null)}
            disabled={!file || loading}
            variant="outlined"
            color="secondary"
          >
            Quitar Imagen
          </Button>
        </div>
        {!!file && (
          <div className="my-4 mx-auto w-80">
            <img src={fileUrl} />
          </div>
        )}
      </div>
      <div className="align-middle justify-end my-8">
        {loading && <LoadingIcon />}
        {!loading && (
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={submitPromo}
            disabled={!name || !file || loading}
          >
            Crear
          </Button>
        )}
      </div>
    </>
  );
};

export default PromoForm;
