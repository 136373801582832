import axios from "axios";

import { CountryLower } from "../constants/countries";
import { DynamicAsset, DynamicAssetCategory } from "../types/promos";
import apiUrl from "./api-config";

import namespaced from "~/src/utils/debug";

const debug = namespaced("api");

const client = axios.create({ baseURL: apiUrl });

export async function listPromos(country?: CountryLower): Promise<DynamicAsset[]> {
  try {
    const res = await client.get<DynamicAsset[]>(
      `${apiUrl}/dynamic-asset?ordering=-created_at&category=${DynamicAssetCategory.PROMO}&country=${
        country || "chile"
      }`,
    );
    return res.data;
  } catch (error) {
    debug("api-promos-error", error);
    return [];
  }
}

export async function createPromo(formData: FormData): Promise<DynamicAsset | undefined> {
  try {
    formData.append("category", DynamicAssetCategory.PROMO);
    const res = await client.post(`${apiUrl}/dynamic-asset/`, formData);
    if (res.status !== 201) return undefined;
    return res.data;
  } catch (error) {
    debug("api-promos-error", error);
  }
}
