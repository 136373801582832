export function capitalizeFirstLetter(text: string): string {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

export function parsePhone(phone: string): string {
  if (phone.startsWith("+")) {
    return phone;
  } else if (phone.startsWith("56")) {
    return `+${phone}`;
  } else if (phone.length === 9) {
    return `+56${phone}`;
  } else if (phone.length === 8) {
    return `+569${phone}`;
  } else {
    return phone;
  }
}
