// eslint-disable-next-line no-shadow
export enum DynamicAssetCategory {
  PROMO = "promo",
  OTHER = "other",
}

export type DynamicAsset = {
  name: string;
  image_url: string;
  category: DynamicAssetCategory;
  redirection_url: string | null | undefined;
  created_at: Date;
};
